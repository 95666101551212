import React, { useState, useEffect } from 'react';
import HomeAppComponent from '../HomeAppComponent';
import LocationAndDate from './LocationAndDate';

const MainComponent = (props) => {
  const [word, setWord] = useState(null);
  const [wholeWord, setwholeWord] = useState(props.match.params.param);
  useEffect(() => {
    // extract first two letters from given url
    // for ex: localhost:3000/swerty
    // sw will be extracted
    // const url = window.location.href;
    const url = props.match.params.param;

    const extractedWord = url.substr(url.length - 6, 2);
    setWord(extractedWord);
    // console.log(extractedWord, "MainComponent")
  }, []);

  return (
    <div>
      {word != null ? (
        <LocationAndDate word={word} wholeWord={wholeWord} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default MainComponent;
