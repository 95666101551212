import './App.css';
import Questionnaire from "./Components/Questionnaire";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Container, Row, Col } from "react-bootstrap";
import { InValidURL } from './Components/InValidURL';
import { API_END_POINT } from './config';

export default function HomeAppComponent({ word, Latitude, Longitude, wholeWord }) {
    const [displayQuestions, setDisplayQuestions] = useState([]);
    const [displayLogicQuestions, setDisplayLogicQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [errLoading, seterrLoading] = useState(false);
    const InvalidURLMsge = "There is no data with mentioned url, please provide a valid one";
    // dummy survey code
    const survey = {
        "surveyid": "40",
        "surveycode": "ea",
        "surveyname": "Online-Offline Purchase Process - Airtel DTH",
        "surveyheadingForeground": {
            "margin": "auto",
            "color": "#FFF",
            "font-size": "27px"
        },
        "surveyheadingBackground": {
            "background-color": "red",
            "padding": "10px",
            "text-align": "start"
        },
        "surveyheading": "Online-Offline Purchase Process - Airtel DTH",
        "survey": {
            "questions": [
                {
                    "id": "1",
                    "code": "Q1",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q1 Please select your preferred language"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "English,Hindi,Tamil,Bengali,Oriya,Marathi,Assamese,Telugu,Gujarati,Kannada,Malayalam"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 1,
                    "columns": 3
                },
                {
                    "id": "2",
                    "code": "Q1d",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q1d Please select the place from where you purchased Airtel DTH connection?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Company Website,Company App,Company Store,Multi Brand Store,Through Sales Executive,Other : (Please Specify)"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 2
                },
                {
                    "id": "3",
                    "code": "Q2",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q2 Considering your Overall experience with Airtel DTH Connection, how likely are you to recommend Airtel DTH Connection to your friends/ family?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 3
                },
                {
                    "id": "4",
                    "code": "Q3",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q3 Considering your recent experience of purchasing Airtel DTH connection, how likely are you to recommend Airtel DTH Connection to your friends/ family?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 4
                },
                {
                    "id": "5",
                    "code": "Q4",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q4 You rated <span style=\"color:blue;\">{Q3}</span> on Airtel DTH PURCHASE EXPERIENCE. Please let us know what all can be improved."
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 4,
                        "relatedQuestionSequence": 4,
                        "relatedAnswer": [
                            0,
                            1,
                            2,
                            3,
                            4,
                            5,
                            6
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 5
                },
                {
                    "id": "6",
                    "code": "Q4",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q4 You rated <span style=\"color:blue;\">{Q3}</span> on Airtel DTH PURCHASE EXPERIENCE. Please let us know the reason for the same."
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 4,
                        "relatedQuestionSequence": 4,
                        "relatedAnswer": [
                            7,
                            8
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 6
                },
                {
                    "id": "7",
                    "code": "Q4",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q4 You rated <span style=\"color:blue;\">{Q3}</span> on Airtel DTH PURCHASE EXPERIENCE. Please let us know what all did you like the most."
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 4,
                        "relatedQuestionSequence": 4,
                        "relatedAnswer": [
                            9,
                            10
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 7
                },
                {
                    "id": "8",
                    "code": "Q4.1",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q4.1 Please select the statement which applies to your Online Purchase of Airtel DTH Connection?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "I purchased it myself from Website/App without any help,I requested for a call back on Website/App & purchased it with the help of Sales executive"
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            "Company Website",
                            "Company App"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 8
                },
                {
                    "id": "9",
                    "code": "Q4.21",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q4.2 How would you rate airtel website/Thanks app on the following attributes, considering airtel Website/Thanks App on providing information about different packs, price, number of channels, etc. that helped you in making your purchase decision?"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Ease of discovering information</span>"
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            "Company Website",
                            "Company App"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 9
                },
                {
                    "id": "10",
                    "code": "Q4.22",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q4.2 How would you rate airtel website/Thanks app on the following attributes, considering airtel Website/Thanks App on providing information about different packs, price, number of channels, etc. that helped you in making your purchase decision?"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Ease of understanding the information</span>"
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            "Company Website",
                            "Company App"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 10
                },
                {
                    "id": "11",
                    "code": "Q4.23",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q4.2 How would you rate airtel website/Thanks app on the following attributes, considering airtel Website/Thanks App on providing information about different packs, price, number of channels, etc. that helped you in making your purchase decision?"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Relevance of information</span>"
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            "Company Website",
                            "Company App"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 11
                },
                {
                    "id": "12",
                    "code": "Q4.24",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q4.2 How would you rate airtel website/Thanks app on the following attributes, considering airtel Website/Thanks App on providing information about different packs, price, number of channels, etc. that helped you in making your purchase decision?"
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Ease of dropping the lead online</span>"
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            "Company Website",
                            "Company App"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 12
                },
                {
                    "id": "13",
                    "code": "Q4.3",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q4.3 Did you receive a CALL- BACK within 3-4 hours of submitting your mobile number on the website/ Thanks app?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "within 2 hours,2-4hours,4- 8hours,More than 8 hours"
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            "Company Website",
                            "Company App"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 13
                },
                {
                    "id": "14",
                    "code": "Q51",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q5 Considering your recent experience with the following attributes, how likely are you to recommend Airtel DTH to your friends/ family."
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Ability of Sales executive to help you decide the best plan with the channels you needed</span>"
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 2,
                        "relatedQuestionSequence": 2,
                        "relatedAnswer": [
                            "Company Website",
                            "Company App",
                            "Company Store",
                            "Multi Brand Store",
                            "Through Sales Executive",
                            "Other : (Please Specify)"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 14
                },
                {
                    "id": "15",
                    "code": "Q52",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q5 Considering your recent experience with the following attributes, how likely are you to recommend Airtel DTH to your friends/ family."
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">Airtel DTH offering good Value for money</span>"
                    },
                    "options": {
                        "English": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 15
                },
                {
                    "id": "16",
                    "code": "Q5",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q5 Considering your recent experience with the following attributes, how likely are you to recommend Airtel DTH to your friends/ family."
                    },
                    "questionsub": {
                        "English": "<span style=\"color:orange;font-size:15pt;\">DTH Service Engineer visited for Installation as per the agreed appointment time</span>"
                    },
                    "options": {
                        "English": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 16
                },
                {
                    "id": "17",
                    "code": "Q6",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q6 Did DTH Installer give you demonstration post installation?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Yes,No"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 17
                },
                {
                    "id": "18",
                    "code": "Q7",
                    "questiontype": "NPS",
                    "question": {
                        "English": "Q7 Considering your recent experience with the Quality of demonstration given post installation, how likely are you to recommend Airtel DTH connection to your friends/ family?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "displayLogic": {
                        "relatedId": 18,
                        "relatedQuestionSequence": 18,
                        "relatedAnswer": [
                            "Yes"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 18
                },
                {
                    "id": "19",
                    "code": "Q8",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q8 Did the DTH Engineer checked signal Quality & Ensured all channels were working fine before leaving?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Yes,No"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 19
                },
                {
                    "id": "20",
                    "code": "Q9",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q9 In order to stay in your budget do you compromise & not add some TV channels in your pack?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Yes,No"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 20
                },
                {
                    "id": "21",
                    "code": "Q10",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q10 At the time of purchasing/installing new DTH Connection, please select the statement applicable to you?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "I have got new Dish from the Company,I am using my old dish"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 21
                },
                {
                    "id": "22",
                    "code": "Q10.1",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q10.1 Which connection were you using before purchasing this new Airtel DTH connection?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "This is my first connection,Free Dish (for which you don’t need to pay any monthly rental),Airtel DTH,Tata Play,Dish TV,Sun Direct,Videocon,Please Specify"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 22
                },
                {
                    "id": "23",
                    "code": "Q11",
                    "questiontype": "MultipleChoice",
                    "question": {
                        "English": "Q11. Please select all the family members from the below list, who watch TV using DTH connection at least 3-4days/week?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "I myself,Spouse (husband/wife),Kids (school going),Kids (college going),Sibling,Parents"
                    },
                    "answerText": [],
                    "isMandatory": true,
                    "questionSequence": 23
                },
                {
                    "id": "24",
                    "code": "Q12",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q12 Select your gender."
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Male,Female"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 24
                },
                {
                    "id": "25",
                    "code": "Q13",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q13 Your Age (in years)."
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 25,
                    "NumberRange": [
                        18,
                        99
                    ]
                },
                {
                    "id": "26",
                    "code": "Q14",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q14 Please select up to what level have you studied?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "School up to 4 years/No schooling/ Illiterate, School 5-9 years, SSC/HSC, Some college but not graduate (Including diploma), Graduate/Post graduate-general (e.g.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com), Graduate/Post graduate-professional (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB), Other : Please Specify"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 26
                },
                {
                    "id": "27",
                    "code": "Q15",
                    "questiontype": "OpenText",
                    "question": {
                        "English": "Q15 Please mention your occupation"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": ""
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 27
                },
                {
                    "id": "28",
                    "code": "Q16",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q16 Are you the person who contributes maximum to the household income?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Yes,No"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 28
                },
                {
                    "id": "29",
                    "code": "Q17",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q17. Up to what level has the person studied who contributes maximum to the household income?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "School up to 4 years/No schooling/ Illiterate, School 5-9 years, SSC/HSC, Some college but not graduate (Including diploma), Graduate/Post graduate-general (e.g.‚ B.A.‚ B.Sc.‚ M.Sc.‚ M.Com), Graduate/Post graduate-professional (M.Tech.‚ B. Tech.‚ MBA.‚ CA.‚ MBBS‚ LLB), Other : Please Specify"
                    },
                    "displayLogic": {
                        "relatedId": 28,
                        "relatedQuestionSequence": 28,
                        "relatedAnswer": [
                            "No"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 29
                },
                {
                    "id": "30",
                    "code": "Q18",
                    "questiontype": "MultipleChoice",
                    "question": {
                        "English": "Q18. Please select all the items that you have at home?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Electricity Connection, Ceiling Fan, LPG Stove, Two wheeler, Colour TV, Refrigerator, Washing Machine, Personal Computer / Laptop, Car/Jeep/Van, Air Conditioner, Agricultural Land, Other : Please Specify"
                    },
                    "displayLogic": {
                        "relatedId": 28,
                        "relatedQuestionSequence": 28,
                        "relatedAnswer": [
                            "No",
                            "Yes"
                        ]
                    },
                    "answerText": [],
                    "isMandatory": true,
                    "questionSequence": 30
                },
                {
                    "id": "31",
                    "code": "Q19",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q19 Do you use Broadband?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Yes,No"
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 31
                },
                {
                    "id": "32",
                    "code": "Q20",
                    "questiontype": "MultipleChoice",
                    "question": {
                        "English": "Q20. Please select the brand/company of broadband connection which you have at home?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Airtel,Den,Mtnl,Bsnl,Hathway,tata,Jio GigaFiber,SITI,MTS,Excitel,Tikona,Nextra,ACT Fibernet,Spectranet,YouBroadband,Gigatel,Triple Play,HCFL/Connect,Fastway,Gtpl,Connect,IOS broadband,Sikka,Alliance, Other:(please specify)"
                    },
                    "displayLogic": {
                        "relatedId": 31,
                        "relatedQuestionSequence": 31,
                        "relatedAnswer": [
                            "Yes"
                        ]
                    },
                    "answerText": [],
                    "isMandatory": true,
                    "questionSequence": 32
                },
                {
                    "id": "33",
                    "code": "Q21",
                    "questiontype": "SingleChoice",
                    "question": {
                        "English": "Q21 Can we share your feedback with Airtel?"
                    },
                    "questionsub": {
                        "English": ""
                    },
                    "options": {
                        "English": "Yes,No"
                    },
                    "displayLogic": {
                        "relatedId": 31,
                        "relatedQuestionSequence": 31,
                        "relatedAnswer": [
                            "Yes",
                            "No"
                        ]
                    },
                    "answerText": "",
                    "isMandatory": true,
                    "questionSequence": 33
                }
            ]
        }
    }

        ;
    useEffect(() => {
        if (word != null || word != "") {

            setLoading(true);

            // call for get api       

            axios.get(`${API_END_POINT.BASE_URL}/api/survey/GetSurveyByCode/${word}`)
                .then(response => {
                    let surveyData = response.data.data;
                    surveyData.survey.questions = surveyData.survey.questions.map(question => { return { ...question, isNavigated: false } })

                    setData(surveyData);
                    //   setData(survey);
                    console.log(response.data.data, "response");

                    if (response.data.data.survey.questions.length) {
                        let displayQuestions = [];
                        let displayLogicQ = []
                        for (let i = 0; i < response.data.data.survey.questions.length; i++) {
                            let question = response.data.data.survey.questions[i];
                            let displayLogic = question.displaylogic;
                            if (!displayLogic) {
                                displayQuestions.push(question);
                            } else {
                                //Handle display logic here
                                displayLogicQ.push(question)
                            }
                        }
                        // console.log(displayQuestions, "checking useEffect")
                        setDisplayQuestions(displayQuestions);
                        if (displayLogicQ.length > 0)
                            setDisplayLogicQuestions(displayLogicQ);

                    }
                    setLoading(false);
                }).catch(ex => {
                    seterrLoading(true);
                });


        }

    }, [])
    return (
        <div className="App">
            {/* {loading && <p>Loading...</p>} */}
            {/* <h4>{word}</h4> */}
            {!loading && displayQuestions.length > 0 ?
                <Questionnaire survey={data}
                    Latitude={Latitude}
                    Longitude={Longitude}
                    wholeWord={wholeWord}
                />
                : <InValidURL msg={InvalidURLMsge}
                    errLoading={errLoading}
                />}
        </div>
    );
}
